let model = {};

// dữ liệu form
model.dataForm = {
    "id": undefined, // optional - Mã tự sinh
    "disabled": undefined, // required - Cho biết trạm có bị disable hay không
    "province_id": undefined, // required, ref: province - Mã tỉnh
    "net_x": undefined, // required - netx
    "url": undefined, // required, pattern: /^(https?:)?/// - Đường dẫn tới web probe
    "loc": // optional - Tọa độ GeoJSON của probe
    {
        "type": undefined, // required, enum(Point) - Loại GeoJSON (chỉ hỗ trợ Point)
        "coordinates": [], // required, length: 2, array items: required, min: -180, max: 180 - Tọa độ [Kinh độ, Vĩ độ]
    },
    "columns": // required - Các trường dữ liệu của probe
    {
    }
};


model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: true,
    total: 0,
    page: 1,
    limit: null,
    offset: null,
    sort: null,
    defaultSort: null,
    //fields: "id, disabled, province_id, net_x, url, loc, columns",
    fields: null,
    filters: null,
    dataSearch: {
        value: {
        }
    },
};

export default model;
