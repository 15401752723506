<script setup>
import { onMounted, ref, reactive, watch } from "vue";
import Layout from "../../layouts/main.vue";
import MapComponent from "../../components/MapComponent.vue";
import MapVietNam from "../../components/MapVietNamComponent.vue";
import mushroom from "cem-primary-api";
import { useRouter } from "vue-router";
import MethodService from "../../service/MethodService";
import LanguaService from "../../service/LanguageService";
import LoadingPage from "../../components/loading.vue";
import PaginationMapInfo from "../../components/PaginationMapInfo.vue";
import Swal from "sweetalert2";
import toastr from "toastr";
import polyline from "google-polyline";
import DataForm from "./dataFormDashboard";
import { FormInstance } from "element-plus";
import en from "element-plus/lib/locale/lang/en";
import vi from "element-plus/lib/locale/lang/vi";
import { langWeb } from "../../stores/lang";

const lang_web = langWeb();
const router = useRouter();
const roles = ref([]);
const ruleFormRef = ref(FormInstance);
let loading = ref(false); // loading
const loadingTable = ref(true);
const indexRowProbe = ref();
const pageSizeDefault = ref(20); // số bản ghi mặc định ở phân trang
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const columns = ref([]);
const tableData = reactive({ value: [] }); // data bảng
const probe_column_config = ref([]);
const arrayColumns = ref([]);
const markerGroup = ref([]); // mảng thông tin các probe
const lngArray = ref([]);
const latArray = ref([]);
const centerMap = [105.8440129, 21.0109351]; // tọa độ mặc định
const zoom = ref("");
const flagDontShowTable = ref(false);
const tokenFragment = ref(""); // token truyền từ primary sang probe
const tokenAccount = ref(""); // token của tài khoản đăng nhập trên primary
const widthContainerCard = ref(0); // độ rộng của card dashboard
const widthSum = ref(0);
const listNetX = ref([]);
const listProvinces = ref([]);
const listProvincesDeparment = ref([]);
const lang = ref(lang_web.showLang.toLocaleUpperCase()); // đa ngôn ngữ cho thông báo swal, toastr
const mapProbe = ref(true);
const IdDistrict = ref(""); // tên huyện đầu tiên trong mảng -> sau la id
const listNameDistrict = ref([]); // mảng các huyện có polygon
const dataMapInfoOk = ref(true); // hiển thị trạng thái loading khi sang màn bản đồ việt nam
const dataForMapVN = ref([]);
const listDate = ref([]); // danh sách ngày theo id huyện
const districtMapInfo = ref([]); // thông tin chi tiết huyện có polygon
const _temp_districtMapInfo = ref([]); // lưu thông tin huyện có polygon vào nhớ tạm để lấy giá trị khi click vào từng ngày
const disabledPrev = ref(false);
const disabledNext = ref(false);
const isDisabledSwitch = ref(false);
const showNameDistrict = ref("");
const flyMap = ref([]);
const toggleSwitch = ref(true);
const changeIconSearch = ref(true);
const arrColumnFilter = ref([]);
const countClickDistrict = ref(0);
const keyMapComponent = ref(0);
const listNameProbe = ref([]); // mảng các probe có polygon
const dataForMapProbe = ref([]);
const IdProbe = ref(""); // tên probe đầu tiên trong mảng -> sau la id
const listDateProbe = ref([]); // danh sách ngày theo id probe
const probeMapInfo = ref([]); // thông tin chi tiết probe có polygon
const _temp_probeMapInfo = ref([]); // lưu thông tin probe có polygon vào nhớ tạm để lấy giá trị khi click vào từng ngày
const disabledProbePrev = ref(false);
const disabledProbeNext = ref(false);
const flyMapProbe = ref([]);
const listProvince = ref([]);
const countClickProbe = ref(0);
const dataMapInfoOkProbe = ref(false);
const showComponentRight = ref(false);
const firstTimeCenter = ref(true);

const get_probe_column_config = async () => {
  try {
    const response = await mushroom.probe_column_config.listAsync({
      filters: ["permission.view=true"],
      sort: "index,title",
      limit: 100,
    });
    let containerCard = document.querySelector(".card");
    widthContainerCard.value = containerCard.clientWidth;
    probe_column_config.value = response.result;
    columns.value = response.result;
    arrColumnFilter.value = [];
    probe_column_config.value.forEach((item) => {
      widthSum.value += item.format.width;
      tableRules.dataSearch.value[item.code] = "";
      item.widthColumn = "";
      arrayColumns.value.push({
        col: item.code,
        title: item?.title_lang?.[lang.value.toLowerCase()] || item.title,
      });
      if (item.permission.filter) {
        arrColumnFilter.value.push({
          title: item?.title_lang?.[lang.value.toLowerCase()] || item.title,
          code: item.code,
        });
      }
    });
    probe_column_config.value.forEach((item) => {
      item.widthColumn = (item.format.width / widthSum.value) * 100;
    });
    probe_column_config.value.forEach((item) => {
      item.title = item?.title_lang?.[lang.value.toLowerCase()] || item.title;
    });
    tableRules.fields = response.result.map((c) => c.code);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const fn_TimKiem = (formEl) => {
  formEl.validate(async (valid) => {
    if (valid) {
      tableRules.filters = { type: localStorage.getItem("type_login") };
      for (let code in tableRules.dataSearch.value) {
        const value = tableRules.dataSearch.value[code];
        if (value == "") continue;
        tableRules.filters[code] = value.trim().toLowerCase();
      }
      tableRules.offset = 0;
      tableRules.page = 1;
      get_data_probe();
    } else return false;
  });
};
const fn_tableSizeChange = (limit) => {
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  get_data_probe();
};
const fn_tableSortChange = (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  get_data_probe();
};
const changeKey = (data) => {
  arrayColumns.value.forEach((item) => {
    data[item.title] = "";
    for (let key in data) {
      if (key == item.col) {
        data[item.title] = data[key];
        delete data[key];
      }
    }
  });
  return data;
};
const get_data_probe = async (flag) => {
  loading.value = false;
  loadingTable.value = true;
  try {
    const response = await mushroom.probe.get_probesAsync({
      body: {
        fields: tableRules.fields,
        filters: tableRules.filters,
        sort: tableRules.sort
          ? [tableRules.sort.replace(/^(-?)columns\./, "$1")]
          : undefined,
      },
    });
    if (response.result) {
      let arrCoordinates = [];
      tokenFragment.value = MethodService.getTokenAccount(tokenAccount.value);
      response.result.forEach(async (item) => {
        const idProvince = item.province_id;
        const codeProvince = listProvince.value?.find(
          (item) => item.id === idProvince
        )?.code3;
        item.code3 = codeProvince;
        item.statusOversee = item.columns.status === "Đang giám sát" ? 1 : 0;
        item.colPopup = { ...item.columns };
        changeKey(item.colPopup);
        let hashValue = "";
        let newUrl = "";
        arrCoordinates.push(item.loc.coordinates);

        if (item.url.includes("#")) {
          newUrl = item.url.substring(0, item.url.indexOf("#"));
          hashValue = item.url.substring(item.url.indexOf("#") + 1);
          if (item.url.includes("?")) {
            newUrl = newUrl + "&" + tokenFragment.value;
          } else newUrl = newUrl + "?" + tokenFragment.value;

          if (hashValue) {
            newUrl = newUrl + "#" + hashValue;
          }
          item.url =
            newUrl +
            "&pid=" +
            item.id +
            "&urlPrimary=" +
            encodeURIComponent(location.origin) +
            "&nameProbe" +
            item.columns.name +
            "&type_login=" +
            localStorage.getItem("type_login") +
            "&lang="+
            localStorage.getItem("language");
        } else {
          if (item.url.includes("?")) {
            item.url =
              item.url +
              "&" +
              tokenFragment.value +
              "&pid=" +
              item.id +
              "&urlPrimary=" +
              encodeURIComponent(location.origin) +
              "&nameProbe" +
              item.columns.name +
              "&type_login=" +
              localStorage.getItem("type_login") +
              "&lang=" +
              localStorage.getItem("language");
          } else
            item.url =
              item.url +
              "?" +
              tokenFragment.value +
              "&pid=" +
              item.id +
              "&urlPrimary=" +
              encodeURIComponent(location.origin) +
              "&nameProbe=" +
              item.columns.name +
              "&type_login=" +
              localStorage.getItem("type_login") +
              "&lang=" +
              localStorage.getItem("language");
        }
        markerGroup.value.push({
          idProbe: item.id,
          net_x: item.net_x,
          idProvince: item.province_id,
          center: item.loc.coordinates,
          columns: item.colPopup,
          disabled: item.disabled,
          url: item.url,
        });
        getCenterMap(arrCoordinates);
      });
      response.result.sort((a, b) => {
        // Compare by statusOversee first
        if (a.statusOversee < b.statusOversee) {
          return 1;
        } else if (a.statusOversee > b.statusOversee) {
          return -1;
        } else {
          // If statusOversee are equal, compare by code3
          return a.code3.localeCompare(b.code3);
        }
      });
      // response.result.sort((a, b) => a.code3.localeCompare(b.code3));
      tableData.value = response.result.slice(
        pageSizeDefault.value * tableRules.page - pageSizeDefault.value,
        pageSizeDefault.value * tableRules.page
      );
      tableRules.total = response.result.length;
      if (flag) {
        keyMapComponent.value += 1;

        if (response.result.length === 0) {
          flagDontShowTable.value = true;
        } else flagDontShowTable.value = false;
      }
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  } finally {
    loadingTable.value = false;
    // loading.value = false;
  }
};
const getCenterMap = (data) => {
  data.forEach((element) => {
    lngArray.value.push(element[0]);
    latArray.value.push(element[1]);
  });
  let max_long = lngArray.value.reduce(function (accumulator, element) {
    return accumulator > element ? accumulator : element;
  });

  let min_long = lngArray.value.reduce(function (accumulator, element) {
    return accumulator < element ? accumulator : element;
  });

  let max_lat = latArray.value.reduce(function (accumulator, element) {
    return accumulator > element ? accumulator : element;
  });

  let min_lat = latArray.value.reduce(function (accumulator, element) {
    return accumulator < element ? accumulator : element;
  });
  let khoangcach = MethodService.distFrom(min_lat, min_long, max_lat, max_long);
  if (khoangcach === 0) {
    centerMap.value = [max_long, max_lat];
  } else {
    centerMap.value = [(max_long + min_long) / 2, (max_lat + min_lat) / 2];
  }

  // console.log("khoangcach", khoangcach);
  // if (khoangcach > 1050) {
  //   zoom.value = 3.8;
  // } else if (khoangcach > 950) {
  //   zoom.value = 4.1;
  // } else if (khoangcach > 800) {
  //   zoom.value = 4.3;
  // } else if (khoangcach > 700) {
  //   zoom.value = 4.7;
  // } else if (khoangcach > 600) {
  //   zoom.value = 5;
  // } else if (khoangcach > 400) {
  //   zoom.value = 5.5;
  // } else if (khoangcach > 360) {
  //   zoom.value = 5.8;
  // } else if (khoangcach > 200) {
  //   zoom.value = 6.2;
  // } else if (khoangcach > 120) {
  //   zoom.value = 7.2;
  // } else if (khoangcach > 50) {
  //   zoom.value = 8.2;
  // } else if (khoangcach > 30) {
  //   zoom.value = 9.3;
  // } else if (khoangcach > 9) {
  //   zoom.value = 10.4;
  // } else if (khoangcach > 3) {
  //   zoom.value = 13;
  // } else if (khoangcach > 1) {
  //   zoom.value = 14;
  // } else zoom.value = 14.4;
  if (khoangcach > 500) {
    zoom.value = 4.7;
  } else if (khoangcach > 1) {
    zoom.value = 5;
  } else {
    zoom.value = 6;
  }
};
const fn_handle = (type, scope, row) => {
  if (type == "update") {
    router.push({
      name: "CapNhatTram",
      params: { id: row.id },
    });
    return;
  }
  if (type == "delete") {
    Swal.fire({
      title: LanguaService[lang.value].swal_noti,
      text: LanguaService[lang.value].swal_probe_delete,
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      cancelButtonText: LanguaService[lang.value].swal_cancel,
      confirmButtonText: LanguaService[lang.value].swal_accept,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.probe.deleteAsync({
            id: row.id,
          });
          if (response.result) {
            toastr.success(LanguaService[lang.value].toastr_delete_success);
            get_data_probe(true);
          }
        } catch (e) {
          console.error("Có lỗi: %o", e);
        }
      }
    });
    return;
  }
  if (type === "view") {
    localStorage.setItem("probeId", row.id);
    let listDomain = [];
    const valueDomain = localStorage.getItem("domainProbe");
    if (valueDomain) {
      listDomain = valueDomain.split(",");
    }
    const domain = row.url.substring(0, row.url.indexOf("/entry.html"));
    if (listDomain.length === 0 || !listDomain.includes(domain)) {
      listDomain.push(domain);
    }
    localStorage.setItem("domainProbe", listDomain.join(","));
    location.href = row.url;
  }
};
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  changeIconSearch.value = !changeIconSearch.value;
  let dom = document.querySelector("#search-arrow");
  if (tableRules.showFormSearch) {
    dom.style.transform = "rotate(180deg)";
  } else dom.style.transform = "rotate(0deg)";
};
const getUserGroups = async () => {
  try {
    const response = await mushroom.user_group.getAllAsync({
      filters: roles.value.includes("AdminLocal")
        ? ["user_id=" + user_id_account.value]
        : "",
    });
    if (response.result) {
      const arrayGroupId = response.result.map((item) => item.group_id);
      await getListProvinceAndListNetX(arrayGroupId);
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListProvinceAndListNetX = async (idsGroup) => {
  try {
    const response = await mushroom.group.listAsync(
      {
        fields: "id,name,province_ids,net_xs,roles",
        filters: ["id:in:" + idsGroup, "roles=Edit"],
        limit: 999,
      },
      { enabledCache: false }
    );
    if (response.result?.length > 0) {
      listProvinces.value = response.result[0]?.province_ids;
      listNetX.value = response.result[0]?.net_xs;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
// lấy về danh sách các tỉnh mà phòng ban của người dùng hiện tại quản lý
const getListProviceDepartment = async () => {
  try {
    const responseProfile = await mushroom.profile.listAsync({
      filters: "id=$$user_id",
    });
    const department_id = responseProfile.result[0].department_id;
    const response = await mushroom.province.listAsync({
      filters: ["department_id=" + department_id],
      limit: 200,
    });
    listProvincesDeparment.value = response.result.map((item) => item.id);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
//lấy về danh sách các probe có polygon
const getGeoProbe = async () => {
  isDisabledSwitch.value = true;
  try {
    const response = await mushroom.probe.getGeoProbesAsync({
      body: {
        fields: tableRules.fields,
        filters: tableRules.filters,
        sort: tableRules.sort
          ? [tableRules.sort.replace(/^(-?)columns\./, "$1")]
          : undefined,
      },
    });
    if (response.result) {
      response.result.forEach(async (item) => {
        if (item.bdy) item.bdy = item.bdy.map(polyline.decode);

        const idProvince = item.province_id;
        const codeProvince = listProvince.value?.find(
          (item) => item.id === idProvince
        )?.code3;
        listNameProbe.value.push({
          value: item.probe_id,
          label: item.columns.name,
          code: codeProvince,
          statusOversee: item.columns.status === "Đang giám sát" ? 1 : 0,
        });
        const probe = markerGroup.value.find(
          (x) => x.idProbe === item.probe_id
        );
        if (probe)
          item.center = {
            lat: probe.center[0],
            lng: probe.center[1],
          };
      });
      // listNameProbe.value = listNameProbe.value.sort((x, y) => {
      //   if (x.code < y.code) return -1;
      //   return 1;
      // });
      listNameProbe.value.sort((a, b) => {
        // Compare by statusOversee first
        if (a.statusOversee < b.statusOversee) {
          return 1;
        } else if (a.statusOversee > b.statusOversee) {
          return -1;
        } else {
          // If statusOversee are equal, compare by code3
          return a.code.localeCompare(b.code);
        }
      });
      dataForMapProbe.value = response.result;
      IdProbe.value = listNameProbe.value[0].value;
      await getNameProbe(IdProbe.value);
      await getProbeMapInfo(
        IdProbe.value,
        new Date().toISOString(),
        "previous"
      );
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    isDisabledSwitch.value = false;
  }
};
// lấy về thông tin chi tiết probe
const getProbeMapInfo = async (idProbe, selectDate, type) => {
  let bodyData = {
    probe_id: idProbe,
    date: selectDate,
    limit: 7,
    type: type, //previous or next
  };
  try {
    const response = await mushroom.probe.getProbeMapInfoAsync({
      body: bodyData,
    });

    if (response.result) {
      if (response.result == 0) {
        listDateProbe.value = [];
        probeMapInfo.value = [];
        _temp_probeMapInfo.value = [];
        return;
      }
      if (type == "previous" && response.result.length == 6) {
        setTimeout(() => {
          disabledProbePrev.value = true;
        }, 500);
        return;
      }
      if (type == "next" && response.result.length == 6) {
        setTimeout(() => {
          disabledProbeNext.value = true;
        }, 500);
        return;
      }

      listDateProbe.value = [];
      probeMapInfo.value = [];
      _temp_probeMapInfo.value = [];
      disabledProbePrev.value = false;
      disabledProbeNext.value = false;
      // hiển thị danh sách ngày
      response.result.forEach((item) => {
        listDateProbe.value.push({
          date: item.date,
          date_for_label_btn: MethodService.formatDateDDMM(item.date),
          color: item?.color || "#1e81b0",
          day: item.day,
          textColor: item.textColor || "#1c1c1c",
        });
        //sắp xếp lại ngày mới nhất ở cuối danh sách
        listDateProbe.value.sort((a, b) => new Date(a.date) - new Date(b.date));
      });

      // lấy thông tin ngày cuối cùng
      response.result[0].info.map((x) => {
        if (x.icon_id) {
          x.urlIcon = mushroom.$file.linkBuilder.thumb.id(x.icon_id).build();
        }
        probeMapInfo.value.push({
          value: x.value,
          label: x.display_text,
          urlIcon: x.urlIcon || "",
          tooltip: x.kpi_tooltip,
        });
      });
      _temp_probeMapInfo.value = [...response.result];
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    dataMapInfoOkProbe.value = true;
  }
};
const getNameProbe = async (idPolygon) => {
  const probe = markerGroup.value.find((x) => x.idProbe === idPolygon);
  // showNameDistrict.value = response.result.type + " " + response.result.name;

  flyMapProbe.value = [probe.center[0], probe.center[1]];
  if (firstTimeCenter.value) {
    flyMapProbe.value = centerMap.value;
  }
};
const setFirstTimeCenter = () => {
  firstTimeCenter.value = false;
};
const prevDataProbe = async (date) => {
  await getProbeMapInfo(IdProbe.value, date.date, "previous");
};
const nextDataProbe = async (date) => {
  await getProbeMapInfo(IdProbe.value, date.date, "next");
};
const currentDataProbe = async (date, index) => {
  probeMapInfo.value = [];
  let lengthData = _temp_probeMapInfo.value.length;
  _temp_probeMapInfo.value[lengthData - index - 1].info.map((x) => {
    if (x.icon_id) {
      x.urlIcon = mushroom.$file.linkBuilder.thumb.id(x.icon_id).build();
    }
    probeMapInfo.value.push({
      value: x.value,
      label: x.display_text,
      urlIcon: x.urlIcon || "",
      tooltip: x.kpi_tooltip,
    });
  });
};
const getNameProvinceProbe = (idProbe) => {
  const valueProbe = dataForMapProbe.value?.find(
    (item) => item.probe_id === idProbe
  );
  const idProvince = valueProbe?.province_id;
  if (idProvince) {
    const codeProvince = listProvince.value?.find(
      (item) => item.id === idProvince
    )?.code3;
    return `${codeProvince} - ${valueProbe.columns.name}`;
  }
  return "";
};
const getListProvince = async () => {
  try {
    const response = await mushroom.province.getAllAsync();
    listProvince.value = response.result;
  } catch (e) {
    console.error("Có lỗi: %o", e);
    listProvince.value = [];
  }
};
const changeProbe = async (idProbe) => {
  IdProbe.value = idProbe;
  countClickProbe.value = 0;
  await getProbeMapInfo(idProbe, new Date().toISOString(), "previous");
  await getNameProbe(idProbe);
  let dom = document.querySelector("#icon-select-down");
  dom.style.transform = "rotate(0deg)";
  indexRowProbe.value = listNameProbe.value.findIndex(
    (item) => item.value === idProbe
  );
  let currentPage = Math.floor(indexRowProbe.value / pageSizeDefault.value + 1);
  fn_tableCurentChange(currentPage);
};

const clickProbe = () => {
  countClickProbe.value += 1;
  let dom = document.querySelector("#icon-select-down");
  if (countClickProbe.value % 2 == 0) {
    dom.style.transform = "rotate(0deg)";
  } else dom.style.transform = "rotate(180deg)";
};
const getIdMapInfoProbe = async (idPolygon) => {
  showComponentRight.value = true;
  IdProbe.value = idPolygon;
  await getProbeMapInfo(idPolygon, new Date().toISOString(), "previous");
  await getNameProbe(IdProbe.value);
};
const changeMapProbe = async (idProbe) => {
  IdProbe.value = idProbe;
  countClickProbe.value = 0;
  await getProbeMapInfo(idProbe, new Date().toISOString(), "previous");
  await getNameProbe(idProbe);
  let dom = document.querySelector("#icon-select-down");
  dom.style.transform = "rotate(0deg)";
  indexRowProbe.value = listNameProbe.value.findIndex(
    (item) => item.value === idProbe
  );
  let currentPage = Math.floor(indexRowProbe.value / pageSizeDefault.value + 1);
  fn_tableCurentChange(currentPage);
};
// function map viet nam
//lấy về danh sách các huyện có polygon
const getGeoDistricts = async () => {
  isDisabledSwitch.value = true;
  try {
    const response = await mushroom.district.getGeoDistrictsAsync({});
    if (response.result) {
      response.result.forEach(async (item) => {
        if (item.bdy) item.bdy = item.bdy.map(polyline.decode);

        if (item.color) {
          listNameDistrict.value.push({
            value: item.district_id,
            label: item.name,
          });
        }
      });
      dataForMapVN.value = response.result;
      IdDistrict.value = response.result[0].district_id;
      await getNameDistrict(IdDistrict.value);
      await getDistrictMapInfo(
        IdDistrict.value,
        new Date().toISOString(),
        "previous"
      );
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    isDisabledSwitch.value = false;
  }
};
// lấy về thông tin chi tiết huyện
const getDistrictMapInfo = async (idDistrict, selectDate, type) => {
  let bodyData = {
    district_id: idDistrict,
    date: selectDate,
    limit: 7,
    type: type, //previous or next
  };
  try {
    const response = await mushroom.district.getDistrictMapInfoAsync({
      body: bodyData,
    });

    if (response.result) {
      if (response.result == 0) {
        listDate.value = [];
        districtMapInfo.value = [];
        _temp_districtMapInfo.value = [];
        return;
      }
      if (type == "previous" && response.result.length == 6) {
        setTimeout(() => {
          disabledPrev.value = true;
        }, 500);
        return;
      }
      if (type == "next" && response.result.length == 6) {
        setTimeout(() => {
          disabledNext.value = true;
        }, 500);
        return;
      }

      listDate.value = [];
      districtMapInfo.value = [];
      _temp_districtMapInfo.value = [];
      disabledPrev.value = false;
      disabledNext.value = false;
      // hiển thị danh sách ngày
      response.result.forEach((item) => {
        listDate.value.push({
          date: item.date,
          date_for_label_btn: MethodService.formatDateDDMM(item.date),
          color: item?.color || "#1e81b0",
          day: item.day,
          textColor: item.textColor || "#1c1c1c",
        });
        //sắp xếp lại ngày mới nhất ở cuối danh sách
        listDate.value.sort((a, b) => new Date(a.date) - new Date(b.date));
      });

      // lấy thông tin ngày cuối cùng
      response.result[0].info.map((x) => {
        if (x.icon_id) {
          x.urlIcon = mushroom.$file.linkBuilder.thumb.id(x.icon_id).build();
        }
        districtMapInfo.value.push({
          value: x.value,
          label: x.display_text,
          urlIcon: x.urlIcon || "",
          tooltip: x.kpi_tooltip,
        });
      });
      _temp_districtMapInfo.value = [...response.result];
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    dataMapInfoOk.value = true;
  }
};
const changeDistrict = async (idDistrict) => {
  countClickDistrict.value = 0;
  await getDistrictMapInfo(idDistrict, new Date().toISOString(), "previous");
  await getNameDistrict(idDistrict);
  let dom = document.querySelector("#icon-select-down");
  dom.style.transform = "rotate(0deg)";
};
const clickDistrict = () => {
  countClickDistrict.value += 1;
  let dom = document.querySelector("#icon-select-down");
  if (countClickDistrict.value % 2 == 0) {
    dom.style.transform = "rotate(0deg)";
  } else dom.style.transform = "rotate(180deg)";
};
const changeMap = async () => {
  toggleSwitch.value = !toggleSwitch.value;
  mapProbe.value = !mapProbe.value;
  if (mapProbe.value == true) {
    IdProbe.value = listNameProbe.value?.[0].value;
    firstTimeCenter.value = true;
    dataMapInfoOk.value = true;
    dataMapInfoOkProbe.value = false;
    await get_data_probe(true);
    await changeProbe(IdProbe.value);
  } else {
    IdProbe.value = "";
    dataMapInfoOk.value = false;
    dataMapInfoOkProbe.value = true;
  }
  setTimeout(() => {
    let domMap = document.querySelector("#switch-name-map");
    let domProbe = document.querySelector("#switch-name-probe");
    if (toggleSwitch.value) {
      domMap.classList.remove("active-switch");
      domProbe.classList.add("active-switch");
    } else {
      domMap.classList.add("active-switch");
      domProbe.classList.remove("active-switch");
    }
  }, 200);

  setTimeout(() => {
    mapProbe.value == true
      ? (dataMapInfoOk.value = false)
      : (dataMapInfoOk.value = true);

    mapProbe.value == true
      ? (dataMapInfoOkProbe.value = true)
      : (dataMapInfoOkProbe.value = false);
  }, 2000);
};
const getNameDistrict = async (idPolygon) => {
  const response = await mushroom.district.findByIdAsync({
    id: idPolygon,
    fields: "id,name,type,center",
  });
  showNameDistrict.value = response.result.type + " " + response.result.name;
  flyMap.value = [response.result.center.lng, response.result.center.lat];
};
const getIdMapInfo = async (idPolygon) => {
  // gọi lại hàm getDistrictMapInfo để đổ dữ liệu sang cột thông tin
  IdDistrict.value = idPolygon;
  await getDistrictMapInfo(idPolygon, new Date().toISOString(), "previous");
  await getNameDistrict(IdDistrict.value);
};
const prevData = async (date) => {
  await getDistrictMapInfo(IdDistrict.value, date.date, "previous");
};
const nextData = async (date) => {
  await getDistrictMapInfo(IdDistrict.value, date.date, "next");
};
const currentData = async (date, index) => {
  districtMapInfo.value = [];
  let lengthData = _temp_districtMapInfo.value.length;
  _temp_districtMapInfo.value[lengthData - index - 1].info.map((x) => {
    if (x.icon_id) {
      x.urlIcon = mushroom.$file.linkBuilder.thumb.id(x.icon_id).build();
    }
    districtMapInfo.value.push({
      value: x.value,
      label: x.display_text,
      urlIcon: x.urlIcon || "",
      tooltip: x.kpi_tooltip,
    });
  });
};
const clickRow = (row) => {
  flyMapProbe.value = [row.loc.coordinates[0], row.loc.coordinates[1]];
  changeMapProbe(row.id);
};
const tableRowClassName = ({ row }) => {
  if (row?.id === IdProbe.value) return "current-row";
  return "";
};
watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();
    widthSum.value = 0;
    get_probe_column_config();
    get_data_probe()
  }
);
const user_id_account = ref("");
onMounted(async () => {
  tokenAccount.value = window.localStorage.getItem(
    "mushroom.tokens" + "[" + mushroom.$using() + "]"
  );
  tableRules.filters = { type: localStorage.getItem("type_login") };
  await get_probe_column_config();
  const response = await mushroom.$auth.meAsync();
  user_id_account.value = response.result.id;
  roles.value = response.result.roles;
  await getListProvince();
  await get_data_probe(true);
  await getGeoProbe();
  await getUserGroups();
  await getListProviceDepartment();
  await getGeoDistricts();
});
</script>

<template>
  <Layout>
    <div class="card card-map">
      <LoadingPage v-if="loading"></LoadingPage>

      <div class="card-body" v-else>
        <div class="switch_map">
          <span class="switch-name" id="switch-name-map">{{
            $t("t-map-vn")
          }}</span>
          <span class="switch-control" @click="changeMap()">
            <img
              src="@/assets/icon/toggle-left.svg"
              alt="toggle"
              v-show="!toggleSwitch"
            />
            <img
              src="@/assets/icon/toggle-right.svg"
              alt="toggle"
              v-show="toggleSwitch"
            />
          </span>
          <span class="switch-name active-switch" id="switch-name-probe">{{
            $t("t-map-probe")
          }}</span>
        </div>
        <!-- theo trạm -->
        <div v-if="mapProbe">
          <div class="map-probe-data">
            <div class="map-left">
              <map-component
                :markerGroup="markerGroup"
                :centerMap="centerMap.value"
                :zoom="zoom"
                :key="keyMapComponent"
                :dataForMapVN="dataForMapProbe"
                :flyMap="flyMapProbe"
                :idProbe="IdProbe"
                :firstTimeCenter="firstTimeCenter"
                @getIdMapInfo="getIdMapInfoProbe"
                @changeMapProbe="changeMapProbe"
                @setFirstTimeCenter="setFirstTimeCenter"
              ></map-component>
            </div>
            <div md="6" class="info map-right">
              <div class="info__loading" v-show="!dataMapInfoOkProbe">
                <div class="info__alert">
                  <i
                    style="font-size: 40px; opacity: 0.5; color: #0f6a33"
                    class="bx bx-loader-alt loading-icon"
                  ></i>
                  <div>{{ $t("t-loading-get-data") }}</div>
                </div>
              </div>
              <div class="info_ok" v-show="dataMapInfoOkProbe">
                <div class="info__footer" v-show="listDateProbe.length > 0">
                  <pagination-map-info
                    :listDate="listDateProbe"
                    :disabledPrev="disabledProbePrev"
                    :disabledNext="disabledProbeNext"
                    @prevData="prevDataProbe"
                    @nextData="nextDataProbe"
                    @currentData="currentDataProbe"
                  ></pagination-map-info>
                </div>

                <div class="info__header">
                  <div class="info_header_nameDistrict">
                    {{ getNameProvinceProbe(IdProbe) }}
                  </div>
                  <el-select
                    class="select-district"
                    v-model="IdProbe"
                    filterable
                    @change="changeProbe"
                    @click="clickProbe"
                    suffix-icon
                    :no-match-text="$t('t-match-data')"
                    :no-data-text="$t('t-no-data')"
                  >
                    <el-option
                      v-for="item in listNameProbe"
                      :key="item.value"
                      :label="item.code + ' - ' + item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <i class="icon-select-down" id="icon-select-down"></i>
                </div>
                <div class="info__body">
                  <div v-if="probeMapInfo.length == 0" class="text-center">
                    <i
                      class="ri-information-line"
                      style="font-size: 40px; opacity: 0.5; color: #e0ba00"
                    ></i>
                    <div>
                      {{ $t("t-no-data-district") }}
                    </div>
                  </div>
                  <el-scrollbar height="373px">
                    <b-row
                      v-if="probeMapInfo.length > 0"
                      class="render-district-mapInfo"
                    >
                      <b-col
                        md="6"
                        class="data-mapInfo"
                        v-for="item in probeMapInfo"
                        :key="item.id"
                      >
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          :content="item.tooltip"
                          placement="top"
                          v-if="item.tooltip"
                        >
                          <div class="data-mapInfo__icon">
                            <img
                              v-if="item.urlIcon && item.urlIcon.length > 0"
                              style="width: 40px; height: 40px"
                              :src="item.urlIcon"
                              alt="icon"
                              id="image"
                            />
                            <img
                              v-else
                              src="../../assets/icon/none-icon.svg"
                              alt=""
                            />
                          </div>
                        </el-tooltip>
                        <div class="data-mapInfo__icon" v-else>
                          <img
                            v-if="item.urlIcon && item.urlIcon.length > 0"
                            style="width: 40px; height: 40px"
                            :src="item.urlIcon"
                            alt="icon"
                            id="image"
                          />
                          <img
                            v-else
                            src="../../assets/icon/none-icon.svg"
                            alt=""
                          />
                        </div>
                        <div class="data-mapInfo__data">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="item.tooltip"
                            placement="top"
                            v-if="item.tooltip"
                          >
                            <div class="info__body--value">
                              {{ item.value }}
                            </div>
                          </el-tooltip>
                          <div class="info__body--value" v-else>
                            {{ item.value }}
                          </div>
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="item.tooltip"
                            placement="top"
                            v-if="item.tooltip"
                          >
                            <div class="info__body--key">
                              {{ item.label }}
                            </div>
                          </el-tooltip>
                          <div class="info__body--key" v-else>
                            {{ item.label }}
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </el-scrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- theo huyện -->
        <div v-if="!mapProbe">
          <div class="map-probe-data">
            <div class="map-left">
              <map-viet-nam
                :dataForMapVN="dataForMapVN"
                :flyMap="flyMap"
                @getIdMapInfo="getIdMapInfo"
              ></map-viet-nam>
            </div>
            <div md="6" class="info map-right">
              <div class="info__loading" v-show="!dataMapInfoOk">
                <div class="info__alert">
                  <i
                    style="font-size: 40px; opacity: 0.5; color: #0f6a33"
                    class="bx bx-loader-alt loading-icon"
                  ></i>
                  <div>{{ $t("t-loading-get-data") }}</div>
                </div>
              </div>
              <div class="info_ok" v-show="dataMapInfoOk">
                <div class="info__footer" v-show="listDate.length > 0">
                  <pagination-map-info
                    :listDate="listDate"
                    :disabledPrev="disabledPrev"
                    :disabledNext="disabledNext"
                    @prevData="prevData"
                    @nextData="nextData"
                    @currentData="currentData"
                  ></pagination-map-info>
                </div>

                <div class="info__header">
                  <div class="info_header_nameDistrict">
                    {{ showNameDistrict }}
                  </div>
                  <el-select
                    class="select-district"
                    v-model="IdDistrict"
                    filterable
                    @change="changeDistrict"
                    @click="clickDistrict"
                    suffix-icon
                    :no-match-text="$t('t-match-data')"
                    :no-data-text="$t('t-no-data')"
                  >
                    <el-option
                      v-for="item in listNameDistrict"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <i class="icon-select-down" id="icon-select-down"></i>
                </div>
                <div class="info__body">
                  <div v-if="districtMapInfo.length == 0" class="text-center">
                    <i
                      class="ri-information-line"
                      style="font-size: 40px; opacity: 0.5; color: #e0ba00"
                    ></i>
                    <div>
                      {{ $t("t-no-data-district") }}
                    </div>
                  </div>
                  <el-scrollbar height="373px">
                    <b-row
                      v-if="districtMapInfo.length > 0"
                      class="render-district-mapInfo"
                    >
                      <b-col
                        md="6"
                        class="data-mapInfo"
                        v-for="item in districtMapInfo"
                        :key="item.id"
                      >
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          :content="item.tooltip"
                          placement="top"
                          v-if="item.tooltip"
                        >
                          <div class="data-mapInfo__icon">
                            <img
                              v-if="item.urlIcon && item.urlIcon.length > 0"
                              style="width: 40px; height: 40px"
                              :src="item.urlIcon"
                              alt="icon"
                              id="image"
                            />
                            <img
                              v-else
                              src="../../assets/icon/none-icon.svg"
                              alt=""
                            />
                          </div>
                        </el-tooltip>
                        <div class="data-mapInfo__icon" v-else>
                          <img
                            v-if="item.urlIcon && item.urlIcon.length > 0"
                            style="width: 40px; height: 40px"
                            :src="item.urlIcon"
                            alt="icon"
                            id="image"
                          />
                          <img
                            v-else
                            src="../../assets/icon/none-icon.svg"
                            alt=""
                          />
                        </div>
                        <div class="data-mapInfo__data">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="item.tooltip"
                            placement="top"
                            v-if="item.tooltip"
                          >
                            <div class="info__body--value">
                              {{ item.value }}
                            </div>
                          </el-tooltip>
                          <div class="info__body--value" v-else>
                            {{ item.value }}
                          </div>
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="item.tooltip"
                            placement="top"
                            v-if="item.tooltip"
                          >
                            <div class="info__body--key">{{ item.label }}</div>
                          </el-tooltip>
                          <div class="info__body--key" v-else>
                            {{ item.label }}
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </el-scrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div v-if="flagDontShowTable" class="table-data table-not-data">
            {{ $t("t-table-not-data") }}
          </div>
          <div v-else class="table-data">
            <div class="text-end search-group">
              <span @click="fn_showFormTimKiem()" class="search-group__btn">
                <span class="search-group__text">{{ $t("t-search") }}</span>
                <img src="@/assets/icon/1.svg" alt="" id="search-arrow" />
              </span>
            </div>
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <div class="container-search">
                <el-form
                  label-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col
                      md="4"
                      v-for="item in arrColumnFilter"
                      :key="item.id"
                    >
                      <el-form-item :label="item.title">
                        <el-input
                          type="text"
                          autocomplete="off"
                          clearable
                          v-model="tableRules.dataSearch.value[item.code]"
                          :placeholder="
                            $t('t_input_text') + ' ' + item.title.toLowerCase()
                          "
                        />
                      </el-form-item>
                    </b-col>
                    <b-col md="4" class="button-search-gr">
                      <div class="d-flex">
                        <div
                          class="button-hide-search"
                          @click="fn_showFormTimKiem()"
                        >
                          {{ $t("t-hide") }}
                        </div>
                        <el-button
                          type="primary"
                          class="button-search"
                          native-type="submit"
                          @click="fn_TimKiem(ruleFormRef)"
                        >
                          {{ $t("t-search") }}
                        </el-button>
                      </div>
                    </b-col>
                  </b-row>
                </el-form>
              </div>
            </b-collapse>
            <el-config-provider :locale="lang_web.showLang == 'vi' ? vi : en">
              <el-table
                :data="tableData.value"
                style="width: 100%"
                @sort-change="fn_tableSortChange"
                @row-click="clickRow"
                class="table-dashboard"
                v-loading="loadingTable"
                :row-class-name="tableRowClassName"
                :empty-text="$t('t-no-data')"
              >
                <el-table-column :label="$t('t-stt')" width="70" align="center">
                  <template #default="scope">
                    <div class="text-center">
                      {{ tableRules.offset + scope.$index + 1 }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  v-for="col in probe_column_config"
                  :key="col.id"
                  :prop="'columns.' + col.code"
                  :label="col.title"
                  :sortable="col.permission.sort"
                  :align="col.format.align"
                  show-overflow-tooltip
                  :min-width="col.widthColumn"
                  :class-name="col.code == 'status' ? 'bg_status' : ''"
                >
                  <template #default="scope">
                    <span v-if="scope.row.columns[col.code]?.length > 0">{{
                      scope.row.columns[col.code]
                    }}</span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('t-action')"
                  align="center"
                  width="155px"
                >
                  <template #default="scope">
                    <div class="btn-group-thao-tac">
                      <el-tooltip
                        class="box-item"
                        effect="light"
                        :content="$t('action_view')"
                        placement="top"
                      >
                        <button
                          type="button"
                          class="button-table"
                          @click="fn_handle('view', scope.$index, scope.row)"
                        >
                          <img src="@/assets/icon/send.svg" alt="send" />
                        </button>
                      </el-tooltip>
                      <el-tooltip
                        class="box-item"
                        effect="light"
                        :content="$t('action_option')"
                        placement="top"
                      >
                        <button
                          type="button"
                          class="button-table button-table__edit"
                          @click="fn_handle('update', scope.$index, scope.row)"
                          v-if="
                            roles.includes('Admin') ||
                            roles.includes('AdminLocal') ||
                            // nếu tài khoản có đơn vị quản lý tỉnh
                            (roles.includes('Edit') &&
                              listProvincesDeparment.includes(
                                scope.row.province_id
                              )) ||
                            listNetX.includes(scope.row.net_x) ||
                            listProvinces.includes(scope.row.province_id)
                          "
                        >
                          <img src="@/assets/icon/edit.svg" alt="edit" />
                        </button>
                      </el-tooltip>
                      <el-tooltip
                        class="box-item"
                        effect="light"
                        :content="$t('action_delete')"
                        placement="top"
                      >
                        <button
                          type="button"
                          class="button-table"
                          @click="fn_handle('delete', scope.$index, scope.row)"
                          v-if="roles.includes('Admin')"
                        >
                          <img src="@/assets/icon/trash.svg" alt="trash" />
                        </button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="pagination-dashboard">
                <el-pagination
                  v-model:currentPage="tableRules.page"
                  v-model:page-size="pageSizeDefault"
                  :page-sizes="tableRules.lengthMenu"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="tableRules.total"
                  @size-change="fn_tableSizeChange"
                  @current-change="fn_tableCurentChange"
                  @prev-click="fn_tablePrevClick"
                  @next-click="fn_tableNextClick"
                />
              </div>
            </el-config-provider>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped lang="scss">
.button-search-gr {
  margin-top: 20px;
}
.search-group__btn {
  cursor: pointer;
}
.search-group__btn img#search-arrow {
  position: relative;
  top: -2px;
  transition: 0.2s;
}

.search-group__text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #407bff;
  margin-right: 14px;
}
.loading-container {
  height: 100vh;
  background-color: #fff;
}
:deep .el-select::-ms-expand {
  display: none;
}
:deep .el-select {
  text-align-last: center;
}
.info {
  height: auto;
}
.info_ok {
  height: 100%;
}
.info__header {
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #407bff;
  margin: 0 26px;
  height: 40px;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 15px;
}
.info__header button {
  position: absolute;
  right: 0;
  top: 0;
}
.info__body {
  padding: 8px 0 0 0;
}
.map-probe-data {
  display: flex;
}
:deep .info__header .el-input__wrapper {
  box-shadow: none;
  background-color: transparent;
}

:deep .info__header .el-input__wrapper:hover,
:deep .info__header .el-input__wrapper:focus-visible,
:deep .el-select .el-input__wrapper,
:deep .el-select .el-input__wrapper.is-focus {
  box-shadow: none !important;
  background-color: transparent;
  outline: none !important;
}
:deep .info__header .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: unset !important;
}
.info_header_nameDistrict {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  background-color: #407bff;
  width: 100%;
  text-align: center;
}
.container-search :deep .el-input__inner {
  height: 34px;
}
.info__header :deep .el-input__inner {
  color: #407bff;
  font-size: 20px;
  font-weight: bold;
}
.info__header :deep input::-webkit-input-placeholder {
  color: #407bff;
}

.info__header :deep input:-moz-placeholder {
  /* Firefox 18- */
  color: #407bff;
}

.info__header :deep input::-moz-placeholder {
  /* Firefox 19+ */
  color: #407bff;
}

.info__header :deep input:-ms-input-placeholder {
  color: #407bff;
}

.info__header :deep input::placeholder {
  color: #407bff;
}
.container-search :deep input::-webkit-input-placeholder {
  color: #a4a4a4;
}

.container-search :deep input:-moz-placeholder {
  /* Firefox 18- */
  color: #a4a4a4;
}

.container-search :deep input::-moz-placeholder {
  /* Firefox 19+ */
  color: #a4a4a4;
}

.container-search :deep input:-ms-input-placeholder {
  color: #a4a4a4;
}

.container-search :deep input::placeholder {
  color: #a4a4a4;
}

.loading-map-vietnam {
  background-color: #fff;
  height: 580px;
  position: absolute;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}
.info__loading {
  position: absolute;
  height: 100%;
  width: 100%;
}
.info__alert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.switch_map {
  position: absolute;
  z-index: 1;
  background: #fff;
  padding: 8px 12px;
  width: 156px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.03);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
}
.map-left {
  padding-right: 24px;
  width: 50%;
}
.fullSize {
  width: 100%;
}
.smallSize {
  width: 50%;
}
:deep .map-right {
  height: 500px;
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  width: 50%;
  position: relative;
  .el-select__wrapper {
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
  }
}
.card-body {
  padding: 0;
  background-color: transparent;
}
.card-map {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  box-shadow: unset;
  background-color: transparent;
}
.modify-btn-search {
  height: 28px;
  line-height: 20px;
  padding: 0 10px;
}

.info__body .row .col-md-6 {
  padding: 6px;
  height: 55px;
  background: #ffffff;
  // box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.05);
  border-radius: 16px;
  margin: 8px 12px;
  width: calc(50% - 24px);
}

.switch-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  /* width: 48px; */
  text-align: center;
}
.active-switch {
  color: #407bff;
  font-weight: 600;
}
.switch-control {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.table-data {
  background-color: #fff;
  border-radius: 16px;
  padding: 0px 24px 0px 24px;
}
.search-group {
  padding: 10px 0;
}
:deep .search-group i {
  position: relative;
  top: 0;
}
:deep .el-table th {
  background-color: rgba(244, 244, 244, 0.5);
  box-shadow: inset 0px -1px 0px #d9e5ff;
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
}
.pagination-dashboard {
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
}
.district-mapInfo-container {
  display: flex;
}
.render-district-mapInfo {
  margin: 0 14px;
  // overflow-y: auto;
  // max-height: 373px;
}
.bg_status span {
  background: #d9e5ff;
  border-radius: 8px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  display: inline-block;
}
.data-mapInfo {
  display: flex;
}
.data-mapInfo__data {
  margin-left: 12px;
}
.el-form-item {
  margin-bottom: 16px;
}
.data-mapInfo__data .info__body--key,
.data-mapInfo__data .info__body--value {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
.data-mapInfo__data .info__body--key {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #1c1c1c;
  opacity: 0.7;
  margin-top: 8px;
}
.data-mapInfo__data .info__body--value {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #407bff;
}
.btn-group-thao-tac {
  border: thin solid #d2d2d2;
  border-radius: 10px;
  padding: 0 6px;
  display: flex;
  justify-content: space-between;
  button img {
    height: 16px;
    width: 16px;
  }
}
.table-dashboard :deep .cell {
  width: fit-content;
  margin: auto;
}

i#icon-select-down {
  transition: 0.2s;
  position: absolute;
  z-index: 1;
  right: 12px;
  pointer-events: none;
}
:deep .pagination-dashboard .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color))
    inset !important;
}
</style>
