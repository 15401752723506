<template>
  <div :id="mapId" class="mapId"></div>
</template>
<script setup>
import mapboxgl from "mapbox-gl";
import { defineEmits, defineProps, onMounted, reactive, ref, watch } from "vue";
// import { useRoute } from "vue-router";
// const router = useRoute();
const defaultColor1 = "#286fbd";
const defaultColor2 = "#063970";
const emit = defineEmits([""]);
const props = defineProps({
  markerGroup: Array,
  centerMap: Array,
  probe_column: Array,
  zoom: Number,
  dataForMapVN: Array,
  flyMap: Number,
});

const mapId = ref("");
const center = [109.8440129, 21.0109351];
mapId.value = "map_" + parseInt(Math.random() * 1e6);
let map = null;
const dataMap = reactive({
  value: {
    type: "FeatureCollection",
    features: [],
  },
});
const fn_initMap = () => {
  document.getElementById(mapId.value).innerHTML = "";
  mapboxgl.accessToken =
    "pk.eyJ1Ijoic2l0ZW5zYyIsImEiOiJjbDV2dHlja3MwZDdiM2lwOWtqaGJ4dTNnIn0.baIM03zTvYrPzEJZOlDtYw";

  map = new mapboxgl.Map({
    container: mapId.value,
    style: "mapbox://styles/mapbox/streets-v11",
    center: [105.954204, 16.2], // lấy toạ độ để view toàn việt nam
    zoom: 4.6,
    attributionControl: false,
  });
  let hoveredStateId = ref(null);

  map.on("load", function () {
    map.resize();
    map.addLayer({
      id: "maine",
      type: "fill",
      source: {
        type: "geojson",
        data: dataMap.value,
      },
      layout: {},
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.8,
          0.5,
        ],
      },
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on("click", "maine", (e) => {
      emit("getIdMapInfo", e.features[0].properties.idMap);
    });
    map.on("mouseenter", "maine", (e) => {
      map.getCanvas().style.cursor = "pointer";
      hoveredStateId.value = e.features[0].id;
      map.setFeatureState(
        { source: "maine", id: hoveredStateId.value },
        { hover: true }
      );
    });

    map.on("mousemove", "maine", async (e) => {
      if (e.features.length > 0) {
        if (hoveredStateId.value !== null) {
          map.setFeatureState(
            { source: "maine", id: hoveredStateId.value },
            { hover: false }
          );
        }
        hoveredStateId.value = e.features[0].id;
        map.setFeatureState(
          { source: "maine", id: hoveredStateId.value },
          { hover: true }
        );
      }

      if (e.features[0]?.properties?.centerLng)
        popup
          .setLngLat([
            e.features[0].properties.centerLng,
            e.features[0].properties.centerLat,
          ])
          .setHTML(
            e.features[0].properties.type + " " + e.features[0].properties.name
          )
          .addTo(map);
    });

    map.on("mouseleave", "maine", () => {
      map.getCanvas().style.cursor = "";
      if (hoveredStateId.value !== null) {
        map.setFeatureState(
          { source: "maine", id: hoveredStateId.value },
          { hover: false }
        );
      }
      hoveredStateId.value = null;
      popup.remove();
    });
  });

  map.addControl(new mapboxgl.NavigationControl());
};

watch(
  () => props.dataForMapVN,
  (newData) => {
    let index = 0;
    newData.forEach((item) => {
      if (!item.bdy) return;
      const polygons = item.bdy;
      const color = item.outOfDate
        ? defaultColor2
        : item.color || defaultColor1;

      polygons.forEach((polygon) => {
        dataMap.value.features.push({
          id: index,
          type: "Feature",
          properties: {
            color: color,
            idMap: item.district_id,
            name: item.name,
            type: item.type,
            centerLng: item.center?.lng,
            centerLat: item.center?.lat,
          },
          geometry: {
            type: "Polygon",
            coordinates: [polygon],
          },
        });
      });

      index++;
    });
  }
);
watch(
  () => props.flyMap,
  (newCenter) => {
    if (!map) return;
    center[0] = newCenter[0];
    center[1] = newCenter[1];
    map.flyTo({
      center: newCenter,
      essential: true,
      zoom: 11,
    });
  }
);

onMounted(() => {
  let index = 0;
  props.dataForMapVN.forEach((item) => {
    if (!item.bdy) return;
    const polygons = item.bdy;
    const color = item.outOfDate ? defaultColor2 : item.color || defaultColor1;

    polygons.forEach((polygon) => {
      dataMap.value.features.push({
        id: index,
        type: "Feature",
        properties: {
          color: color,
          idMap: item.district_id,
          name: item.name,
          type: item.type,
          centerLng: item.center?.lng,
          centerLat: item.center?.lat,
        },
        geometry: {
          type: "Polygon",
          coordinates: [polygon],
        },
      });
    });
    index++;
  });
  fn_initMap(props.centerMap ? props.centerMap : center);
});
</script>

<style>
.mapId {
  position: relative;
  width: 100%;
  height: 500px;
}
/* .mapboxgl-canvas {
  height: 570px;
  width: 100% !important;
} */
.mapboxgl-canvas-container {
  height: 580px;
}
.marker-on,
.marker-off {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 35px;
  width: 35px;
  height: 35px;
}
.marker-on {
  background-image: url("../assets/images/marker.png");
}
.marker-off {
  background-image: url("../assets/images/marker-off.png");
}
.title-probe {
  padding: 0 5px;
  margin-bottom: 5px;
}
.mapboxgl-popup-close-button,
.mapboxgl-popup-close-button:hover {
  background-color: rgba(var(--vz-danger-rgb));
  color: #fff;
}
.mapboxgl-popup-content {
  min-width: 180px;
  padding: 0;
}
.card-header-map {
  background-color: #151529;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 7px;
}
.mapboxgl-popup-content {
  text-align: center;
}
</style>
